import React from 'react';
import { Stepper } from '@/components/287634/stepper/stepper';
import { H2, P } from '@/components/287634/typography';

const StepPin = () => (
  <section className="relative flex flex-col items-center">
    <Stepper step={1} />
    <H2 className="mb-5 mt-[68px] text-center text-primary">Crear código de seguridad</H2>
    <P className="mx-5 mb-10 text-center text-primary">
      El código de 4 dígitos garantiza que tus operaciones están protegidas en todo momento. Te lo pediremos siempre que inicies sesión y hables con Silbo.
    </P>
  </section>
);

export { StepPin };
